import {
  BMT_PATH,
  GE_PATH,
  MENU_V1_PATH,
  MENU_V2_PATH,
  NEW_BMT_PATH,
  NEW_GE_PATH,
  NEW_MENU_V1_PATH,
  NEW_MENU_V2_PATH,
  NEW_NAV_PAGES_PATH,
  NEW_SETTINGS_PATH,
  PAGES_PATH,
  SETTINGS_PATH,
} from 'contexts/navigationLinksContext'
import { DEFAULT_TOAST_DURATION, FF_NAMES } from 'src/constants'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  getConfiguration,
  isConfigurationSetUp,
} from 'store/configuration/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from '@reach/router'
import AppLayout from 'components/AppLayout'
import BrowseMenuGQLPage from 'pages/browse-menu-gql'
import BrowseMenuTreeGqlPage from './browse-menu-tree-gql'
import { Channel } from 'store/channels/types'
import { ConfigurationProps } from 'store/types'
import Custom404 from 'pages/404'
import { DISMISS_TOAST_MESSAGE } from 'modules/toast-messages/actions'
import EmptyConfigScreen from 'components/TempScreens/EmptyConfigScreen'
import { FETCH_I18N_LOCALES } from 'store/i18n/actions'
import GlobalElements from './global-elements'
import Menu from 'pages/menu'
import Pages from './pages'
import ServerErrorScreen from 'components/TempScreens/ServerErrorScreen'
import Settings from 'pages/settings'
import SidebarNavigation from 'components/SidebarNavigation'
import Spinner from 'components/Spinner'
import { ToastMessage } from 'modules/toast-messages/types'
import ToastSnackbar from 'components/ToastSnackbar'
import { getChannels } from 'store/channels/selectors'
import { getLocales } from 'store/i18n/selectors'
import { getToastMessageState } from 'modules/toast-messages/selectors'
import httpStatusCodes from 'http-status-codes'
import { INIT_CHANNELS as initChannels } from 'store/channels/actions'
import some from 'lodash/some'
import { useFlag } from '@unleash/proxy-client-react'
import useLoadConfig from 'hooks/useLoadConfig'
import useUnleashContext from 'hooks/useUnleashContext'

const ViewWithSideBar = ({
  customComponent = null,
}: {
  customComponent?: JSX.Element
}): JSX.Element => {
  useUnleashContext()
  useLoadConfig()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stableDispatch = useCallback(dispatch, [dispatch])
  const { kind, message, visible } = useSelector(
    getToastMessageState
  ) as ToastMessage
  const { pathname } = useLocation()
  const isConfigSetUp = useSelector(isConfigurationSetUp)
  const { loaded = false, status = httpStatusCodes.OK } = useSelector(
    getConfiguration
  ) as ConfigurationProps
  const hasStatusError = status >= httpStatusCodes.BAD_REQUEST
  const { channels } = useSelector(getChannels) as { channels?: Channel[] }
  const locales = useSelector(getLocales)
  const i18n = useFlag(FF_NAMES.unleashFFs.I18N)

  const navNameChange = useFlag('navigation-name-change') ?? false

  useEffect(() => {
    if (loaded) {
      if (!channels) {
        stableDispatch(initChannels())
      }
      if (i18n && !locales) {
        stableDispatch(FETCH_I18N_LOCALES())
      }
    }
  }, [stableDispatch, channels, loaded, locales, i18n])

  useEffect(() => {
    const timerId = setTimeout(() => {
      stableDispatch(DISMISS_TOAST_MESSAGE())
    }, DEFAULT_TOAST_DURATION)
    return () => clearTimeout(timerId)
  }, [stableDispatch, message])

  const featuresList = useMemo(() => {
    return {
      i18n: { enabled: i18n, locales },
      multiChannel: { enabled: some(channels), channels },
    }
  }, [i18n, channels, locales])

  const renderMainContent = () => {
    if (loaded) {
      if (hasStatusError) {
        return <ServerErrorScreen status={status} />
      } else if (!isConfigSetUp) {
        return <EmptyConfigScreen />
      } else {
        if (customComponent) {
          return customComponent
        }
        if (!navNameChange) {
          switch (pathname) {
            case PAGES_PATH:
              return <Pages channels={featuresList.multiChannel.channels} />
            case GE_PATH:
              return <GlobalElements featuresList={featuresList} />
            case BMT_PATH:
              return <BrowseMenuTreeGqlPage />
            case MENU_V2_PATH:
              return <BrowseMenuGQLPage />
            case MENU_V1_PATH:
              return <Menu />
            case SETTINGS_PATH:
              return <Settings />
            case '/xpm/':
              void navigate(PAGES_PATH)
              break
            default:
              return <Custom404 />
          }
        } else {
          switch (pathname) {
            case NEW_NAV_PAGES_PATH:
              return <Pages channels={featuresList.multiChannel.channels} />
            case NEW_GE_PATH:
              return <GlobalElements featuresList={featuresList} />
            case NEW_BMT_PATH:
              return <BrowseMenuTreeGqlPage />
            case NEW_MENU_V2_PATH:
              return <BrowseMenuGQLPage />
            case NEW_MENU_V1_PATH:
              return <Menu />
            case NEW_SETTINGS_PATH:
              return <Settings />
            case '/experiences/':
              void navigate(NEW_NAV_PAGES_PATH)
              break
            default:
              return <Custom404 />
          }
        }
      }
    }
    return <Spinner variant='fullScreen' />
  }

  return (
    <AppLayout>
      <AppLayout.Sidebar>
        <SidebarNavigation />
      </AppLayout.Sidebar>
      <AppLayout.Container>
        <ToastSnackbar visible={visible} message={message} kind={kind} />
        {renderMainContent()}
      </AppLayout.Container>
    </AppLayout>
  )
}

export default ViewWithSideBar
